:root {
    --DarkBlue: rgb(14, 35, 54);
    --LBlue: rgb(156, 163, 167);
    --myOrange: #F07C41;
    --myMidBlue: #445e74;
    --myClearBlue: #ffffff;
    --myLowBlue: #ffffff;
  }

html{
    /* box-sizing: border-box;
    overflow-x: hidden; */
    min-height: 100%;
    position:relative;
    scroll-behavior: smooth;
}

body {
    /* background: #fff; */
    /* padding: 0; */
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-bottom: 40px;
    background: linear-gradient(to bottom, rgb(255, 255, 255), rgb(228, 228, 228));
}

body.Dark {
    background: linear-gradient(to bottom, var(--DarkBlue), #000);
    color:white;
}
