.Navbar{
    height: 60px;
    transition: background-color 0.5s ease;
    background-color: var(--LBlue);
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.4);
}

.Navbar.Dark{
    background-color: var(--DarkBlue);
}

.Burger {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    z-index: 99;
    height: 100%;
    width: 100%;
    top: 22px;
    left: 15px;
    height: 22px;
    width: 22px;
}

.spinner {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #fff;
}

.horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

.diagonal.part-1 {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
}

.diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

.horizontal.Checked {
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 0;
}
.diagonal.part-1.Checked{
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
}
.diagonal.part-2.Checked{
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -9px;
}

.sidebarMenu {
    height: calc(100% - 60px);
    position: absolute;
    z-index: 1;
    left: 0;
    width: 250px;
    transform: translateX(-250px);
    transition: transform 200ms ease-in-out;
    background: linear-gradient(180deg, var(--LBlue), rgb(12, 27, 41) );
}

.sidebarMenu.Dark{
    background: linear-gradient(180deg, var(--DarkBlue), #000000 100%);
}

.sidebarMenu.Checked {
    transform: translateX(0);
    box-shadow: 10px 0 5px -4px rgba(0, 0, 0, 0.199);
}

.SubSidebarMenu{
    position:absolute;
    z-index: 1;
    background-color: #000000;
    width: 100%;
    height: calc(100% - 60px);
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
}

.SubSidebarMenu.Checked{
    opacity: 0.2;
    visibility: visible;
}

.sidebarMenu .divLi{
    list-style: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.BarLink {
    color: #FFF;
    text-decoration: none;
}

.BarLink:hover {
    color: rgb(92, 43, 68);
    text-decoration: none;
}

.BarUserPic{
    object-fit: contain;
    object-fit: cover;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
}

.NavUserPic{
    position:absolute;
    right: 0px;
    margin-top: 10px;
    margin-right: 10px;
    object-fit: contain;
    object-fit: cover;
    
}

.BarIcon{
    margin-bottom:-5px
}


/* DARK MODE */


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
