footer{
    height: 40px; 
    color: white;
    width:100%;
    position: absolute;
    left: 0;
    bottom: 0; 
    transition: background-color 0.5s ease;
    background-color: var(--LBlue);
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.4);
}

footer.Dark{
    background-color: var(--DarkBlue);
}

.mainFooterTitle{
    margin: 0 auto;
    width: fit-content;
    margin-top: 12px;
    font-size: 12px;
}