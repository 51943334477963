.NotifMainDiv {
    position: fixed;
    width: 90%;
    max-width: 500px;
    top: 2.5%;
    right: 0;
    left: 0;
    margin: 0 auto;
    z-index: 100;
    transition: transform 0.5s ease;
    transform: translateX(-600%);
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
}

.NotifMainDiv.open{
    transform: translateX(0);
}

.NotifMsg{
    text-align: center;
    font-size: 20px;
    padding: 20px;
    border-radius: 8px;
    color: white;
}

.success{
    background-color: green;
    background: linear-gradient(to right, #0e1e2bcb, green);
}

.warning{
    background-color: orange;
    background: linear-gradient(to right, #223f6bab, orange);
}

.error{
    background-color: red;
    background: linear-gradient(to right, #223f6bab, red);
}

.info{
    background: linear-gradient(to right, #223f6bab, rgb(64, 113, 216));
}
